import React, { Fragment } from 'react';
import LandingPage from '../components/LandingPage';

const Page = () => (
  <div>
    <LandingPage />
  </div>
);

async function getInitialProps() {
  return {
    namespacesRequired: ['common', 'landing', 'login', 'footer', 'values', 'benefits'],
  };
}

Page.getInitialProps = getInitialProps;

export default Page;
