import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import ThemeProfiles from '../Theme/ThemeProfiles';
import { useDarkMode, useMediaQuery } from '../hooks';
import { useTranslation } from '../i18n';
import { Icon } from '../Theme';

const Layout = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  align-items: stretch;
  /* margin: 50px 20%; */
  margin: 20px 40px;
  min-height: 0px;
  min-width: 400px;
  height: 100%;
  background-color: ${({ theme: { backgroundPrimary } }) => backgroundPrimary};
  border-radius: 25px;
  /* background-color: ${({ theme: { main } }) => main}; */

  @media only screen and (max-width: 414px) {
    /* margin: 30px 5%; */
    margin: 10px 5px;
    margin-bottom: 0px;
    padding: 20px 5px;
    border-radius: 0px;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin: 20px 10px;
  @media only screen and (max-width: 414px) {
    justify-content: center;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const Title = styled.p`
  font-size: 1.2em;
  color: ${({ theme: { fontOnPrimary } }) => fontOnPrimary};
  margin: 5px;
  font-weight: bold;
`;

const SubTitle = styled.p`
  font-size: 1em;
  color: ${({ theme: { main } }) => main};
  margin: 5px;
  font-weight: bold;
`;

// const GridLayout = styled.div`
//   display: grid;
//   width: 100%;
//   grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
// `;
/**
 * List of BenefitComponents linking to origin benefitPage
 */
const LandingStatistics = () => {
  const { t } = useTranslation('benefits');
  const darkModeActive = useDarkMode();
  const isSmall = useMediaQuery(['(max-width: 414px)']);

  return (
    <ThemeProvider theme={darkModeActive ? ThemeProfiles.themeDark : ThemeProfiles.themeLight}>
      <Layout>
        {/* {lols.map((item) => {
          return ( */}
        <ItemContainer>
          <Icon
            name="holding-hands"
            marked
            size={isSmall ? 60 : 80}
            margin={isSmall ? '5px 20px' : '10px 50px'}
          />
          <TextContainer>
            <Title>Mitglieder insgesamt</Title>
            <SubTitle>über 1.000.000</SubTitle>
          </TextContainer>
        </ItemContainer>

        <ItemContainer>
          <Icon
            name="chart"
            marked
            size={isSmall ? 60 : 80}
            margin={isSmall ? '5px 20px' : '10px 50px'}
          />
          <TextContainer>
            <Title>Neue Mitglieder</Title>
            <SubTitle>über 20.000 pro Woche</SubTitle>
          </TextContainer>
        </ItemContainer>

        <ItemContainer style={{ justifyContent: 'center' }}>
          <TextContainer style={{ alignItems: 'center' }}>
            <Title>Weiblich</Title>
            <SubTitle>42%</SubTitle>
          </TextContainer>
          <Icon
            name="genderMulti"
            marked
            size={isSmall ? 60 : 80}
            margin={isSmall ? '5px 20px' : '10px 50px'}
          />
          <TextContainer style={{ alignItems: 'center' }}>
            <Title>Männlich</Title>
            <SubTitle>58%</SubTitle>
          </TextContainer>
        </ItemContainer>
        {/* );
        })} */}
      </Layout>
    </ThemeProvider>
  );
};
export default LandingStatistics;
