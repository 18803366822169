import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import LandingBox from './LandingBox';
import ThemeProfiles from '../Theme/ThemeProfiles';
import landingBoxTexts from './LandingBoxTexts';
import { useDarkMode, useMediaQuery } from '../hooks';
import { useTranslation } from '../i18n';

const Layout = styled.div`
  display: flex;
  position: relative;
  flex: 2;
  flex-direction: column;
  justify-content: center;
  /* margin: 50px 20%; */
  margin: 20px;
  padding: 20px;
  min-height: 0px;
  height: auto;
  background-color: ${({ theme: { main } }) => main};
  @media only screen and (max-width: 1500px) {
    /* margin: 40px 10%; */
    margin: 10px;
    padding: 10px;
  }
  @media only screen and (max-width: 1240px) {
    /* margin: 30px 5%; */
    margin: 10px;
  }
`;

const GridLayout = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  /* display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
`;
/**
 * List of BenefitComponents linking to origin benefitPage
 */
const LandingBoxesList = () => {
  const isSmall = useMediaQuery(['(max-width: 414px)']);
  const { t } = useTranslation('benefits');
  const darkModeActive = useDarkMode();

  return (
    <ThemeProvider theme={darkModeActive ? ThemeProfiles.themeDark : ThemeProfiles.themeLight}>
      <Layout>
        <GridLayout>
          {isSmall
            ? landingBoxTexts.slice(0, 3).map((item) => {
                return (
                  <LandingBox
                    key={item.title}
                    iconName={item.iconName}
                    title={t(item.title)}
                    text={t(item.text)}
                  />
                );
              })
            : landingBoxTexts.map((item) => {
                return (
                  <LandingBox
                    key={item.title}
                    iconName={item.iconName}
                    title={t(item.title)}
                    text={t(item.text)}
                  />
                );
              })}
        </GridLayout>
      </Layout>
    </ThemeProvider>
  );
};
export default LandingBoxesList;
