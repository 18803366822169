/**
 * Array with benefit Objects
 */
const landingBoxTexts = [
  {
    title: 'freeRegister',
    text: 'freeRegisterTextShort',
    iconName: 'box-profile',
  },
  {
    title: 'discretion',
    text: 'discretionTextShort',
    iconName: 'box-silence',
  },
//   {
//     title: 'highChances',
//     text: 'highChancesText',
//     iconName: 'holding-hands',
//   },
  {
    title: 'dailySingles',
    text: 'dailySinglesTextShort',
    iconName: 'box-heart',
  },
  {
    title: 'ownCommunity',
    text: 'ownCommunityTextShort',
    iconName: 'box-community',
  },
//   {
//     title: 'nearUsers',
//     text: 'nearUsersTextShort',
//     iconName: 'box-poi',
//   },
];

export default landingBoxTexts;
