import React, { useEffect, useRef, useState, useCallback } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { animated, useSpring } from 'react-spring';
import { useCookies } from 'react-cookie';
import CookieConsent from 'react-cookie-consent';
import { useRouter } from 'next/router';
import Config from '../config';
import { withTranslation, i18n } from '../i18n';
import { useDarkMode, useMeasure } from '../hooks';
import ThemeProfiles from '../Theme/ThemeProfiles';
import { Background, Icon, Text, StyleGuide, DocumentTitle } from '../Theme';
import LandingPageHeader from './LandingPageHeader';
import Register from './Register';
import LoadingScreen from '../Loading/LoadingScreen';
import LandingBoxesList from '../LandingBoxes';
import LandingPageFooter from '../Footer/LandingPageFooter';
import LandingStatistics from './LandingStatistics';

const ContentDiv = styled.div`
  display: flex;
  flex-direction: row;
  background-image: url('/static/images/background.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  object-fit: cover;
  align-items: flex-start;
  justify-content: flex-end;
  min-height: 85vh;
  padding: 50px 10%;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0px;
  }
`;

const CookieText = styled.p`
  text-align: center;
  font-size: 15px;
  color: white;
`;

const CookieLink = styled.a`
  color: white;
  transition: 0.3s;
  :hover {
    opacity: 0.8;
  }
`;

const DiagonalBox = styled.div`
  position: absolute;
  top: -75px;
  width: 100%;
  background: ${({ theme: { backgroundGradient } }) => backgroundGradient};
  height: 150px;
  /* z-index: 20; */
  transform: skewY(-3deg);
`;

const Wrapper = styled.div`
  display: flex;
  padding: 20px 5%;
  margin-top: 100px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
  @media only screen and (max-width: 1440px) {
    padding: 20px 2%;
  }
  @media only screen and (max-width: 414px) {
    padding-bottom: 0px;
  }
`;

/**
 * Landing Page Component with Login and Register
 */
const LandingPage = ({ t, isLoading, logout, token, ...props }) => {
  const [cookies, , removeCookie] = useCookies(['Token', 'StayLogged', 'fromApp']);
  const darkModeActive = useDarkMode();
  const prevToken = useRef(token);

  const router = useRouter();

  useEffect(() => {
    const isFramed = () => {
      if (typeof window === 'undefined') return false;
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    };
    if (isFramed()) window.open(window.location.href, '_top');
  }, []);

  useEffect(() => {
    if (token && prevToken.current) {
      if (cookies.StayLogged) {
        router.push('/main');
      } else {
        logout();
        removeCookie('Token', { path: '/' });
      }
    }
    prevToken.current = token;
  }, [logout, token]);

  return (
    <div style={{ background: StyleGuide.colors.primary }}>
      <ThemeProvider theme={darkModeActive ? ThemeProfiles.themeDark : ThemeProfiles.themeLight}>
        <Background scroll>
          <DocumentTitle text={Config.appName} />

          {isLoading && <LoadingScreen />}
          {!cookies?.fromApp && (
            <CookieConsent
              buttonStyle={{ color: '#000', background: '#fff', borderRadius: '20px' }}
              buttonText={t('landing:accept')}
            >
              <CookieText>
                {t('landing:cookieUse')}
                <CookieLink href={Config.urls.privacy}>{t('moreInfo')}</CookieLink>
              </CookieText>
            </CookieConsent>
          )}
          <LandingPageHeader {...props} token={token} />
          <ContentDiv>
            <Register {...props} />
          </ContentDiv>
        </Background>
        <div style={{ position: 'relative' }}>
          <DiagonalBox />
        </div>
        <Wrapper>
          <LandingBoxesList />
          <LandingStatistics />
          {/* <div style={{ flex: 1 }} /> */}
        </Wrapper>
        <LandingPageFooter style={{ zIndex: 11 }} />
      </ThemeProvider>
    </div>
  );
};
export default withTranslation(['landing', 'login', 'common'])(LandingPage);
