import React, { useState, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { animated, useSpring } from 'react-spring';
import { Icon, Text, StyleGuide, Title } from '../Theme';
// 140
const Box = styled(animated.div)`
  display: flex;
  flex-direction: column;
  margin: 20px;
  padding: 10px;
  width: 300px;
  /* flex: 1; */
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 10px;
  /* cursor: pointer; */
  @media only screen and (max-width: 1440px) {
  width: 250px;
  }
  @media only screen and (max-width: 1024px) {
    margin: 10px;
  }
`;

const BoxTitle = styled(Title)`
  font-weight: bold;
  color: inherit;
  text-align: left;
  padding: 2px;
  margin: 10px;
`;

const BoxText = styled(Text)`
  color: inherit;
  text-align: left;
  margin: 10px;
  line-height: 24px;
  font-size: 1em;
`;
/**
 * Single BenefitComponent linking to origin benefitPage
 */
const LandingBox = ({ iconName, text, title }) => {
  const [focused, setFocused] = useState(false);
  const theme = useContext(ThemeContext);
  const props = useSpring({
    to: {
      background: focused ? '#fff' : theme.background,
      color: focused ? '#000' : '#fff',
      boxShadow: focused ? '0px 0px 35px 5px rgba(255,255,255, 0.6)' : '0px 0px 0px 0px rgba(255,255,255, 0.6)',
    },
  });
  return (
    <Box
      style={props}
      marked={focused.toString()}
      onMouseEnter={() => setFocused(true)}
      onMouseLeave={() => setFocused(false)}
    >
      <Icon
        name={iconName}
        margin="10px"
        color={focused ? theme.main : 'white'}
        size={70}
        style={{ transition: '.5s' }}
      />
      <BoxTitle marked={focused.toString()}>{title}</BoxTitle>
      <BoxText marked={focused.toString()}>{text}</BoxText>
    </Box>
  );
};

export default LandingBox;
