import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import { parseISO } from 'date-fns';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { withTranslation } from '../i18n';
import Config from '../config';
import StyleGuide from '../Theme/StyleGuide';
import ThemeProfiles from '../Theme/ThemeProfiles';
/**
 * This is Footer.
 * @reactProps {Object} t - i18next Objekt für die Übersetzungen
 */

const FooterContainer = styled.div`
  display: flex;
  padding: 0px 10%;
  height: auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme: { backgroundPrimary } }) => backgroundPrimary};
  border-top: ${({ theme: { splitter } }) => `2px solid ${splitter}`};
  margin-top: 70px;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7); */
  @media screen and (max-width: 414px) {
    flex-direction: column;
    border: none;
  }
`;

const FooterTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-around;
  margin: 15px;
  @media screen and (max-width: 500px) {
    margin: 10px;
  }
`;

const BadgeWrapper = styled.div`
  display: none;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 10px;
  @media screen and (max-width: 500px) {
    display: flex;
  }
`;

const Badge = styled.img`
  margin: 10px;
  height: 50px;
  width: 150px;
  border-radius: 5px;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
`;

const FooterLink = styled.span`
  color: ${({ theme: { fontOnPrimary } }) => fontOnPrimary};
  font-size: 0.6em;
  margin: 0 10px;
  text-decoration: none;
  :hover {
    opacity: 0.5;
  }
  cursor: pointer;
  @media screen and (max-width: 414px) {
    /* font-size: 0.5em; */
    margin: 4px 10px;
    min-width: 20%;
    flex: 1;
  }
`;

const CopyrightWrapper = styled.div`
  display: flex;
  flex: 1;
  font-size: 0.6em;
  width: 100%;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 414px) {
    justify-content: center;
  }
`;

const Copyright = styled.p`
  color: ${({ theme: { fontOnPrimary } }) => fontOnPrimary};
  text-align: center;
  @media screen and (max-width: 414px) {
    font-size: 0.8em;
  }
`;

const NoticeWrapper = styled.div`
  display: flex;
  flex: 1;
  font-size: 0.6em;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: ${({ theme: { backgroundPrimary } }) => backgroundPrimary};
`;

const Notice = styled.p`
  margin: 0 0 5px 0;
  font-style: italic;
  color: ${({ theme: { fontOnPrimary } }) => fontOnPrimary};
  text-align: center;
  @media screen and (max-width: 414px) {
    font-size: 0.8em;
  }
`;

/**
 * Footer Component including links StoreBadges and StaticPages
 */
const Footer = ({ t, width }) => {
  const router = useRouter();

  const [cookies, setCookie] = useCookies(['fromApp']);

  useEffect(() => {
    if (router.query?.from === 'app') {
      setCookie('fromApp', true, {
        path: '/',
        expires: parseISO(Date.now() + 14 * 24 * 60 * 60 * 1000),
      });
    }
  }, [router]);
  return (
    <FooterContainer>
      <FooterTop>
        {!cookies?.fromApp && router?.query?.from !== 'app' && (
          <BadgeWrapper>
            <a href={Config.urls.appleStore}>
              <Badge src="/static/images/MacOSbadge.png" />
            </a>
            <a href={Config.urls.googlePlaystore}>
              <Badge src="/static/images/google-play-badge.png" />
            </a>
          </BadgeWrapper>
        )}
        <LinkWrapper>
          <Link href="/agb">
            <FooterLink>{t('tos').toUpperCase()}</FooterLink>
          </Link>

          <Link href="/impressum">
            <FooterLink>{t('imprint').toUpperCase()}</FooterLink>
          </Link>

          <Link href="/faq">
            <FooterLink>{t('faq').toUpperCase()}</FooterLink>
          </Link>

          <Link href="/datenschutz">
            <FooterLink>{t('privacy').toUpperCase()}</FooterLink>
          </Link>

          <Link href="/richtlinien">
            <FooterLink>{t('guidelines').toUpperCase()}</FooterLink>
          </Link>
          <Link href="/support">
            <FooterLink>{t('support').toUpperCase()}</FooterLink>
          </Link>
        </LinkWrapper>
      </FooterTop>
      <CopyrightWrapper>
        <Copyright>{`Copyright © ${new Date().getFullYear()} Natchd GmbH - ${t('allRightsReserved')}`}</Copyright>
      </CopyrightWrapper>
      <NoticeWrapper>
        <Notice>{t('notice')}</Notice>
      </NoticeWrapper>
    </FooterContainer>
  );
};

export default withTranslation('footer')(Footer);
