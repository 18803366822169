import React, { useState, useContext, useRef, useEffect } from 'react';
import Router from 'next/router';
import Link from 'next/link';
import styled, { ThemeContext } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import aes from 'crypto-js/aes';
import sha256 from 'crypto-js/sha256';
import CryptoJS from 'crypto-js';
import uuid from 'uuid/v4';
import { format, formatISO, isValid, parse } from 'date-fns';
import { PreCheckLogin } from '../services/webAppEndPoint';
import { useTranslation } from '../i18n';
import {
  StyleGuide,
  Text,
  RoundedButton,
  InputTextWithIcon,
  InputRadio,
  RegisterButton,
  Icon,
  CustomAlert,
  ThemedText,
  DimBackground,
} from '../Theme';
import { useCompareWithPrevious, useMeasure, useMediaQuery } from '../hooks';
import Tracking from '../tracking';
import UseOutsideClick from '../Theme/UseOutsideClick';

const Container = styled.div`
  /* display: flex;
  flex-wrap: wrap; */
  max-width: 550px;
  /* flex-direction: column;
  justify-content: center; */
  align-items: stretch;
  background: transparent;
  overflow: hidden;
  /* background: ${({ setZ, theme: { backgroundPrimary } }) =>
    setZ ? backgroundPrimary : 'transparent'}; */
  border-radius: 20px;
  z-index: 11;
  ${StyleGuide.landingShadow};
  margin-top: 5%;
  margin-bottom: 30px;
  @media screen and (max-height: 1081px) {
    margin-top: 2%;
  }
  @media screen and (max-width: 414px) {
    margin-top: 5px;
    box-shadow: none;
    max-width: 85vw;
    /* max-width: 300px; */
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  /* min-height: calc(85vh - 10%); */
  justify-content: flex-start;
  align-items: stretch;
  background: ${({ theme: { backgroundSecondary } }) => backgroundSecondary};
  padding: 20px;
  @media screen and (max-width: 414px) {
    /* background: transparent; */
    /* min-height: unset; */
    /* min-height: 150px; */
    /* width: 250px; */
  }
`;

const InputBox = styled(InputRadio)`
  display: flex;
  flex: 1;
  margin: 5px;
`;

const RadioContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${(props) => props.padding};
`;

const RadioContainer = styled(animated.div)`
  display: flex;
  flex-direction: row;
  padding: 3px;
  @media screen and (max-width: 414px) {
    /* flex-direction: column; */
  }
`;

const Title = styled(Text)`
  margin: 15px auto;
  font-size: 2em;
  margin-bottom: 15px;
  @media screen and (max-width: 414px) {
    font-size: 1.2em;
    line-height: 1.5em;
  }
`;

const FieldContainer = styled(animated.div)`
  display: flex;
  flex-direction: column;
  /* margin-top: 10px; */
  overflow: hidden;
  position: relative;
  padding: 0px 3px;
  /* z-index: 11; */
`;

const RegisterBtn = styled(RegisterButton)`
  padding: 0px 3px;
  margin: 10px 5px 0px 5px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
`;

const ProviderBtnContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* width: 100%; */
  align-items: center;
  justify-content: center;
`;

const generateFingerPrintHash = () => {
  const { width, height, pixelDepth } = window.screen;
  const { userAgent } = window.navigator;
  return sha256(width + height + pixelDepth + userAgent.replace(/\D+/, '')).toString(
    CryptoJS.enc.Hex
  );
};

const encode = (message) => {
  return aes.encrypt(message, generateFingerPrintHash());
};

const isValidEmail = (email) => {
  return /^[\w-._]+/.test(email) && /.+@.+/.test(email) && /@[^@]+.\w+$/.test(email);
};

const isValidPassword = (password) => {
  return password.length >= 6;
};

/**
 * Register Component of LandingPage
 */
const Register = ({ status, register, login }) => {
  const theme = useContext(ThemeContext);
  const isSmall = useMediaQuery(['(max-width: 414px)']);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordCheck, setPasswordCheck] = useState('');
  const [gender, setGender] = useState(null);
  const [missingMail, setMissingMail] = useState(false);
  const [nonMatchingPasswords, setNonMatchingPasswords] = useState(false);
  const [missingGender, setMissingGender] = useState(false);
  const [lookingFor, setLookingFor] = useState('');
  const { t } = useTranslation(['login', 'common', 'footer', 'profile', 'benefits', 'landing']);
  const [modalVisible, setModalVisible] = useState(false);
  const [inputFormVisible, setInputFormVisible] = useState(false);
  const outsideRef = useRef();
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);

  const [inputContainerRef, { height: inputContainerHeight }] = useMeasure();
  // 20 ist für Margin von Unterelementen
  // const { height, paddingBottom, paddingTop } = useSpring({
  //   height: inputFormVisible ? inputContainerHeight + 10 : 0,
  //   paddingTop: inputFormVisible ? '30px' : '5px',
  //   paddingBottom: inputFormVisible ? '30px' : '5px',
  //   // config: { mass: providerFormVisible && isSmall ? 3 : 1 },
  // });
  const { height: fieldHeight, marginTop } = useSpring({
    height: inputFormVisible ? inputContainerHeight : 0,
    marginTop: inputFormVisible ? '5px' : '5px',
    // config: { mass: inputFormVisible ? 3 : 1 },
  });

  useCompareWithPrevious(status, (prevStatus) => {
    if (status && prevStatus !== status && prevStatus !== undefined) {
      switch (status) {
        case 7024: {
          if (email && password && passwordCheck && gender && lookingFor) {
            Router.push({
              pathname: '/register',
              query: { email, gender, pw: encode(password).toString() },
            });
          }
          break;
        }
        case 7046: {
          setModalVisible(true);
          break;
        }
        default:
          break;
      }
    }
  });

  const handleSubmit = () => {
    Tracking.track('landingRegister');
    if (!inputFormVisible) {
      setInputFormVisible(true);
      return;
    }
    if (!email) {
      setMissingMail(true);
    }

    setInvalidEmail(!isValidEmail(email));
    setInvalidPassword(!isValidPassword(password));

    if (password !== passwordCheck) {
      setNonMatchingPasswords(true);
      setPasswordCheck('');
      setPassword('');
    }
    if (!gender) {
      setMissingGender(true);
    }
    if (
      gender &&
      email &&
      password === passwordCheck &&
      isValidPassword(password) &&
      isValidEmail(email)
    ) {
      PreCheckLogin(email.trim())
        .then((userExisting) => {
          if (userExisting) setModalVisible(true);
          else {
            Router.push({
              pathname: '/register',
              query: { email, gender, pw: encode(password).toString() },
            });
          }
        })
        .catch((error) => {
          console.log('err', error);
        });
    }
  };

  UseOutsideClick(outsideRef, () => {
    if (inputFormVisible) {
      setInputFormVisible(false);
    }
  });

  return (
    <Container ref={outsideRef}>
      {/* {inputFormVisible && <DimBackground style={{ zIndex: 12 }} />} */}
      <Content
        as={animated.div}
      >
        <FieldContainer>
          <Title color={theme.main} style={{ fontWeight: 'bold' }}>
            {t('meetNewPeople')}
          </Title>
          {/* <RightContent> */}
          <RadioContainer style={{ marginTop }}>
            <RadioContent padding="0px">
              <Text color={theme.main} style={{ fontWeight: 'bold' }} textAlign="left">
                {t('iAm')}
              </Text>
              <Wrapper style={{ flexDirection: 'column' }}>
                <InputBox
                  flex={1}
                  margin="5px"
                  error={missingGender}
                  checked={gender === 'male'}
                  onChange={() => {
                    setGender('male');
                    if (!lookingFor) {
                      setLookingFor('female');
                    }
                    setInputFormVisible(true);
                    setMissingGender(false);
                  }}
                  text={t('common:male')}
                />

                <InputBox
                  margin="5px"
                  flex={1}
                  checked={gender === 'female'}
                  onChange={() => {
                    setGender('female');
                    if (!lookingFor) {
                      setLookingFor('male');
                    }
                    setInputFormVisible(true);
                    setMissingGender(false);
                  }}
                  text={t('common:female')}
                  error={missingGender}
                />
              </Wrapper>
            </RadioContent>
            <RadioContent padding="0px">
              <Text color={theme.main} style={{ fontWeight: 'bold' }} textAlign="left">
                {t('profile:searchFor')}
              </Text>
              <Wrapper style={{ flexDirection: 'column' }}>
                <InputBox
                  margin="5px"
                  flex={1}
                  checked={lookingFor === 'female'}
                  onChange={() => {
                    setLookingFor('female');
                    setInputFormVisible(true);
                  }}
                  text={t('aWoman')}
                />

                <InputBox
                  margin="5px"
                  flex={1}
                  value="woman"
                  checked={lookingFor === 'male'}
                  onChange={() => {
                    setLookingFor('male');
                    setInputFormVisible(true);
                  }}
                  text={t('aMan')}
                />
              </Wrapper>
            </RadioContent>
          </RadioContainer>

          <FieldContainer style={{ height: fieldHeight }}>
            <div ref={inputContainerRef}>
              <InputTextWithIcon
                stretch
                value={email}
                error={missingMail || invalidEmail}
                placeholder={t('common:mail')}
                errorPlaceholder={t('missingMail')}
                onChange={({ target: { value: val } }) => {
                  setEmail(val.trim());
                  setMissingMail(false);
                }}
                margin="10px 0px"
                iconName="post"
              />

              <InputTextWithIcon
                stretch
                minLength={6}
                type="password"
                value={password}
                error={nonMatchingPasswords || invalidPassword}
                isPassword
                placeholder={t('password')}
                errorPlaceholder={t('missingPassword')}
                onChange={({ target: { value: val } }) => {
                  setPassword(val);
                  setNonMatchingPasswords(false);
                }}
                iconName="password"
                margin="10px 0px"
              />

              <InputTextWithIcon
                stretch
                minLength={6}
                type="password"
                value={passwordCheck}
                isPassword
                error={nonMatchingPasswords || invalidPassword}
                errorPlaceholder={t('nonMatchingPassword')}
                placeholder={t('confirmPassword')}
                onChange={({ target: { value: val } }) => {
                  setPasswordCheck(val);
                  setNonMatchingPasswords(false);
                }}
                onKeyUp={(event) => {
                  if (event.keyCode === 13) {
                    handleSubmit();
                  }
                }}
                margin="10px 0px"
                iconName="password"
              />
              {nonMatchingPasswords && (
                  <Text color={theme?.main}>{t('nonMatchingPassword')}</Text>
              )}

              {invalidPassword && (
                  <Text color={theme?.main}>{t('invalidPassword')}</Text>
              )}

              {invalidEmail && (
                  <Text color={theme?.main}>{t('invalidEmail')}</Text>
              )}
            </div>
          </FieldContainer>
          <RegisterBtn onClick={handleSubmit}>
            <Text color="inherit" fat>
              {t('benefits:freeRegister')}
            </Text>
          </RegisterBtn>
          <Wrapper as={animated.div} style={{ flexDirection: 'column', paddingTop: marginTop }}>
            <Text color={theme?.fontOnPrimary} style={{ margin: '20px 10px', fontWeight: 500 }}>
              {t('landing:orRegisterWith')}
            </Text>
            <ProviderBtnContainer>
              <FacebookLogin
                isMobile={false}
                appId="277450333754649"
                onFailure={() => Router.push('/login-error')}
                scope="public_profile,email,user_birthday,user_location,user_gender"
                fields="email,first_name,last_name, name,birthday,gender,location.fields(location),picture.width(1080).height(1920)"
                callback={async ({
                  accessToken,
                  id,
                  email: fbMail,
                  gender: fbGender,
                  location,
                  first_name: name,
                  picture,
                  birthday,
                }) => {
                  let alreadyExist = false;

                  // Backend Check ob Mail oder googleId vergeben
                  await PreCheckLogin(fbMail?.trim())
                    .then((userExisting) => {
                      if (userExisting) alreadyExist = true;
                    })
                    .catch((error) => {
                      console.log('err', error);
                    });

                  await PreCheckLogin(id)
                    .then((userExisting) => {
                      if (userExisting) alreadyExist = true;
                    })
                    .catch((error) => {
                      console.log('err', error);
                    });

                  if (alreadyExist) {
                    await login({ token: accessToken, userId: id, origin: 'facebook' });
                  } else if (
                    accessToken &&
                    id &&
                    fbGender &&
                    fbMail &&
                    isValid(parse(birthday, 'MM/dd/yyyy', new Date())) &&
                    picture &&
                    location &&
                    name
                  ) {
                    const llocation = location?.location;
                    register({
                      token: accessToken,
                      userId: id,
                      email: fbMail,
                      gender: fbGender === 'female' ? 'female' : 'male',
                      birthday: formatISO(new Date(birthday)),
                      name,
                      location: {
                        latitude: llocation?.latitude,
                        longitude: llocation?.longitude,
                        city: llocation?.city,
                        country: llocation?.country,
                      },
                      picture: { uri: picture?.data?.url, mimeType: 'image/jpeg', id: uuid() },
                      origin: 'facebook',
                      previewPath: picture?.data?.url,
                      leadPage: Router?.query?.state,
                    });
                  } else {
                    Router.push({
                      pathname: '/auth-register',
                      query: {
                        token: accessToken,
                        userId: id,
                        email: fbMail,
                        name,
                        origin: 'facebook',
                        gender: fbGender === 'female' ? 'female' : 'male',
                        picture: JSON.stringify({
                          uri: picture?.data?.url,
                          mimeType: 'image/jpeg',
                          id: uuid(),
                        }),
                        birthday: isValid(parse(birthday, 'MM/dd/yyyy', new Date()))
                          ? format(new Date(birthday), 'yyyy-MM-dd')
                          : null,
                      },
                    });
                  }
                }}
                render={(renderProps) => (
                  <RoundedButton
                    style={{
                      borderRadius: '25px',
                      minWidth: '200px',
                    }}
                    flex={1}
                    backgroundColor={StyleGuide.colors.facebook}
                    padding="0px 15px"
                    margin="5px"
                    onClick={() => {
                      Tracking.track('landingFBRegister');
                      renderProps.onClick();
                    }}
                  >
                    <Icon name="facebook" color={StyleGuide.colors.fontOnPrimary} size={25} />
                    <Text
                      color={StyleGuide.colors.fontOnPrimary}
                      fat
                      style={{ paddingLeft: '20px' }}
                    >
                      {'Facebook'}
                    </Text>
                  </RoundedButton>
                )}
              />
              <GoogleLogin
                clientId="736249156738-km2ug0pomq0bhpbc3smelgadeg1h586n.apps.googleusercontent.com"
                redirectUri="https://natchd.com"
                render={(renderProps) => (
                  <RoundedButton
                    style={{
                      borderRadius: '25px',
                      minWidth: '200px',
                    }}
                    flex={1}
                    backgroundColor={StyleGuide.colors.google}
                    padding="0px 15px"
                    margin="5px"
                    onClick={() => {
                      renderProps.onClick();
                    }}
                  >
                    <Icon name="google" color={StyleGuide.colors.fontOnPrimary} size={25} />
                    <Text
                      color={StyleGuide.colors.fontOnPrimary}
                      fat
                      style={{ paddingLeft: '20px' }}
                    >
                      {'Google'}
                    </Text>
                  </RoundedButton>
                )}
                responseType="id_token"
                onSuccess={async (response) => {
                  let alreadyExist = false;
                  // Daten ziehen
                  const { tokenId, googleId, profileObj } = response;
                  const { imageUrl, email: gMail, givenName, name } = profileObj;

                  // Backend Check ob Mail oder googleId vergeben
                  await PreCheckLogin(googleId)
                    .then((userExisting) => {
                      if (userExisting) alreadyExist = true;
                    })
                    .catch((error) => {
                      console.log('err', error);
                    });

                  await PreCheckLogin(gMail?.trim())
                    .then((userExisting) => {
                      if (userExisting) alreadyExist = true;
                    })
                    .catch((error) => {
                      console.log('err', error);
                    });

                  if (alreadyExist) {
                    setModalVisible(true);
                  } else {
                    Router.push({
                      pathname: '/auth-register',
                      query: {
                        token: tokenId,
                        userId: googleId,
                        email: gMail,
                        name: givenName ?? name,
                        origin: 'google',
                        picture: JSON.stringify({
                          uri: imageUrl,
                          mimeType: 'image/jpeg',
                          id: uuid(),
                        }),
                      },
                    });
                  }
                }}
                onFailure={(response) => console.log(response, 'GOOGLE FAIL')}
                cookiePolicy="single_host_origin"
              />
            </ProviderBtnContainer>
          </Wrapper>
        </FieldContainer>
        <ThemedText
          style={{ margin: '15px 5px 5px 5px', fontSize: '0.8em', color: theme?.fontOnPrimary }}
        >
          {`${t('applying')} `}
          <Link href="/agb">
            <span style={{ cursor: 'pointer', fontWeight: '500' }}>{t('footer:tos')}</span>
          </Link>
          {` ${t('and')} `}
          <Link href="/datenschutz">
            <span style={{ cursor: 'pointer', fontWeight: '500' }}>{t('login:privacyHint')}</span>
          </Link>
          <br />
          <div style={{"font-style": "italic", "font-size":"0.6em", "line-height": "1em", "padding-top": "0.2em"}}>{t('footer:notice')}</div>
        </ThemedText>
      </Content>
      <CustomAlert
        show={modalVisible}
        onAccept={() =>
          Router.push({ pathname: '/login-error', query: { showWithoutError: true } })
        }
        onDecline={() => setModalVisible(false)}
        acceptText={t('toLoginScreen')}
        alertTitle={t('registerFail')}
        alertText={t('cannotRegisterAgain')}
        picName="forbidden"
      />
    </Container>
  );
};
export default Register;
